.events-section {
  min-height: auto;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  padding: 3rem 0;
  margin-top: 10vh;
}

.events-section h1 {
  text-align: center;
  margin: 0 0 3rem 0;
  font-family: "Poppins";
}

.events-cricle {
  width: 390px;
  height: 390px;
  padding: 24px;
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  border: 1px dashed transparent;
  border-radius: 50%;
  position: relative;
  z-index: -1;
}

.ec-icon1 {
  height: 18px;
  width: 18px;
  rotate: 45deg;
  position: absolute;
  left: 50px;
  top: 45px;
}

.ec-icon2 {
  height: 18px;
  width: 18px;
  rotate: 135deg;
  position: absolute;
  right: 50px;
  top: 45px;
}

.ec-icon3 {
  height: 18px;
  width: 18px;
  rotate: -45deg;
  position: absolute;
  left: 50px;
  bottom: 45px;
}

.ec-icon4 {
  height: 18px;
  width: 18px;
  rotate: -135deg;
  position: absolute;
  right: 50px;
  bottom: 45px;
}

.ec-btn {
  position: relative;
  padding: 0;
  cursor: pointer;
  color: #fff;
  border: none;
  width: 163px;
  height: 163px;
}

.ec-btn img {
  top: 20px;
  left: 18px;
  width: 70%;
}

.ec-btn1 {
  border-top-left-radius: 100%;
}

.ec-btn2 {
  border-top-right-radius: 100%;
}

.ec-btn3 {
  border-bottom-left-radius: 100%;
}

.ec-btn4 {
  border-bottom-right-radius: 100%;
}

.ec-btn1:hover,
.ec-btn1.active {
  animation-name: scalediv;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
}

.ec-btn2:hover,
.ec-btn2.active {
  animation-name: scalediv;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
}

.ec-btn3:hover,
.ec-btn3.active {
  animation-name: scalediv;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
}

.ec-btn4:hover,
.ec-btn4.active {
  animation-name: scalediv;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
}

@keyframes scalediv {
  100% {
    transform: scale(1.05);
  }
}

.ec-center-content {
  background: #fff;
  width: 204px;
  height: 204px;
  position: absolute;
  background: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ec-logo-content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.ec-logo-content h3 {
  font-size: 24px;
  font-weight: 600;
  line-height: 125%;
  margin: 0;
  padding: 0;
}

.ec-logo-content span {
  max-width: 170px;
  font-size: 14px;
  font-weight: 390;
  line-height: 160%;
}

.ec-logo {
  margin-bottom: 10px;
  width: 75px;
  height: 18px;
  position: relative;
}

@media only screen and (max-width: 1400px) {
  .ec-btn {
    width: 164px;
    height: 164px;
  }
}

.ec-popup {
  height: auto;
  width: auto;
  position: absolute;
  padding: 16px;
  border-radius: 20px;
  background: #f2f7ff;
  box-shadow: rgba(0, 0, 0, 0.2) 0 5px 15px;
}

.ec-popup1 {
  display: none;
  top: 0;
  left: -330px;
}

.ec-popup1.active {
  display: block;
}

.ec-popup2 {
  display: none;
  top: 0;
  right: -330px;
}

.ec-popup2.active {
  display: block;
}

.ec-popup3 {
  display: none;
  top: 70px;
  left: -330px;
}

.ec-popup3.active {
  display: block;
}

.ec-popup4 {
  display: none;
  top: 70px;
  right: -330px;
}

.ec-popup4.active {
  display: block;
}

.triangle-svg {
  width: 50px;
  position: absolute;
  height: 112px;
  background: url("https://www.cvent.com/themes/custom/themekit/css/../images/product_wheel/sp2.svg")
    no-repeat;
}

.ec-popup1 .triangle-svg {
  top: 0;
  rotate: 180deg;
  left: auto;
  right: -49px;
}

.ec-popup2 .triangle-svg {
  top: 40px;
  right: auto;
  left: -49px;
}

.ec-popup3 .triangle-svg {
  top: 190px;
  rotate: 180deg;
  left: auto;
  right: -49px;
}

.ec-popup4 .triangle-svg {
  top: 230px;
  left: -49px;
  right: auto;
}

.ac-content2 {
  width: 278px;
}

.ac-content2 label {
  background: linear-gradient(90deg, #1b4f92bb 0, #0077b6 100%);
  font-size: 16px;
  margin-top: 11px;
  position: relative;
  z-index: 1;
  display: block;
  cursor: pointer;
  border-radius: 100px;
  padding: 16px 0;
  /* color: #161851; */
  line-height: 125%;
  font-weight: 600;
  margin-left: 0;
  margin-right: 0;
  box-shadow: 0 0 12px 2px rgba(0, 0, 0, 0.1);
  text-align: center;
  color: white;
}

.ec-popup-close {
  display: none;
}
.events-section-wrap{
  z-index:1 !important;
} 
@media only screen and (max-width: 1070px) {
  .events-section-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index:1 !important;
  }

  .events-cricle {
    position: relative;
    width: 300px;
    height: 300px;
    padding: 17px;
    gap: 10px;
    z-index:-1 !important;
  }

  .ec-icon1 {
    height: 16px;
    width: 16px;
    left: 30px;
    top: 40px;
  }

  .ec-icon2 {
    height: 16px;
    width: 16px;
    right: 30px;
    top: 40px;
  }

  .ec-icon3 {
    height: 16px;
    width: 16px;
    left: 30px;
    bottom: 40px;
  }

  .ec-icon4 {
    height: 16px;
    width: 16px;
    right: 30px;
    bottom: 40px;
  }

  .ec-btn {
    width: 127px;
    height: 127px;
  }

  .ec-center-content {
    width: 160px;
    height: 160px;
  }

  .ec-logo-content {
    max-width: 116px;
  }

  .ec-logo-content h3 {
    font-size: 20px;
  }

  .wheel-product-desc {
    display: none;
  }

  .ec-logo {
    width: auto;
    height: auto;
    font-size: 30px !important;
  }

  .ec-popup {
    bottom: -370px !important;
    top: auto !important;
    left: -10px !important;
    right: auto !important;
  }

  .triangle-svg {
    display: none !important;
  }

  .ec-popup-close {
    display: block;
    position: absolute;
    right: 10px;
    top: 5px;
  }

  .ec-popup-close .x-mark {
    font-size: 20px;
    cursor: pointer;
  }

  .events-section {
    padding: 3rem 0;
    margin-top: 15vh;
  }

  .ec-btn1 text {
    margin-left: 5px !important;
  }
}

@media only screen and (max-width: 575px) {
  .events-section {
    margin-top: 15vh;
  }
}
