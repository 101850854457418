.ai-chat-button {
  padding: 0.4rem 32px;
  border-radius: 4px;
  border: none;
  margin: 0 0 0 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  font-family: "Poppins";
  width: 310px;
  transition: 0.3s;
  color: white;
  background-color: #1b4f92;
  animation: float 3s ease-in-out infinite, colorShift 6s ease-in-out infinite;
}

@keyframes float {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
}

@keyframes colorShift {
  0% {
    background-color: #1b4f92;
  }
  25% {
    background-color: #4169e1;
  }
  50% {
    background-color: #4682b4;
  }
  75% {
    background-color: #6495ed;
  }
  100% {
    background-color: #1b4f92;
  }
}

.ai-chat-button:hover {
  opacity: 0.9;
}

.ai-chat-button-img {
  width: 40px;
  height: 40px;
  margin-right: 10px;
  transition: transform 0.3s ease-in-out;
}

.ai-chat-button:hover .ai-chat-button-img {
  animation: bounce 0.6s ease-in-out infinite;
}

@media only screen and (max-width: 400px) {
  .ai-chat-button {
    padding: 0.5rem 16px;
    gap: 6px;
  }
}
