.spark-login-heading{

  font-family: Poppins;
  font-size: 2rem;
  font-weight: 600;

}





/* Login.css */
/* .login-container {
    max-width: 400px;
    margin: 100px auto;
    padding: 30px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    align-items: center;
    text-align: center;
  }
 .login-container .login-image{
    width: 150px;
    height: 50px;
    margin-bottom: 20px;
  } */

  /* .wrapper {
    display: flex;
    align-items: center;
    flex-direction: column; 
    justify-content: center;
    width: 100%;
    min-height: 100%;
    padding: 20px;
  }
 
  .login-container  input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  } */
  /* .login-container  h2 {
    margin-bottom: 20px;
  }
  .login-container .login-heading{
    font-family: Poppins
  }
  .login-input-container{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    width: 100%;
    height:400px;
    padding: 10px;
    background-color: red;
  }
 
  
  .login-container  button {
    width: 100%;
    padding: 10px;
    background-color: #25274D;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .login-container  button:hover {
    background-color: #1A1B3D;
  }

  
  .error-message {
    color: red;
    margin-top: 10px;
  }
   */