.slider-section {
  padding: 0rem 2rem;
  margin-top: 15vh;
}

.slider-title {
  width: 700px;
  margin: 0 0 2.5rem 0;
  font-family: "Poppins";
}

.slider-btn-wrap {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.slider-btn {
  border: 1px solid #d9d9d9;
  padding: 1.3rem;
  border-radius: 8px;
  background: #fafafa;
  display: flex;
  cursor: pointer;
}

.slider-btn img {
  height: 32px;
  width: 32px;
}

.divider-vr {
  width: 1px;
  background: #d9d9d9;
  margin-right: 22px;
  margin-left: 22px;
  min-height: 30px;
}

.slider-btn-title {
  font-size: 20px;
  line-height: 20px;
  margin-bottom: 0 !important;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-family: "Poppins";
}

.slider-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 1.5rem 0;
}

.slider-info-wrap {
  display: flex;
  border-radius: 8px;
  background: #fafafa;
  box-shadow: 3px 10px 24px 0px rgba(158, 158, 158, 0.1),
    12px 42px 44px 0px rgba(158, 158, 158, 0.09),
    27px 94px 59px 0px rgba(158, 158, 158, 0.05),
    48px 168px 70px 0px rgba(158, 158, 158, 0.01),
    75px 262px 76px 0px rgba(158, 158, 158, 0);
}

.siw-image {
  min-height: 430px;
  border-radius: 8px 0px 0px 8px;
  max-height: 430px;
  object-fit: cover;
  vertical-align: middle;
  border-style: none;
  width: 50%;
}

.siw-info {
  padding: 65px 32px 0 32px;
  position: relative;
  width: 50%;
}

.siw-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 150%;
  margin-top: 0;
  margin-bottom: 1rem;
  font-family: "Poppins";
}

.siw-desc {
  color: #454545;
  margin-top: 24px;
  font-size: 16px;
  line-height: 150%;
  margin-bottom: 1rem;
  font-family: "Poppins";
}

.siw-info a {
  border-radius: 0px 0px 8px 0px;
  text-decoration: none;
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 16px 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 75px;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: 130%;
  font-family: "Poppins";
}

.siw-img-loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 991px) {
  .slider-section {
    padding: 5rem 6rem;
  }

  .slider-info-wrap {
    display: block;
  }

  .siw-image {
    min-height: 1px;
    border-radius: 8px 8px 0px 0px;
    max-height: 300px;
    width: 100%;
  }

  .siw-info {
    padding: 40px 0 0 0;
    width: 100%;
    height: 250px;
  }

  .siw-title {
    padding: 0px 20px;
    margin: 0;
  }

  .siw-desc {
    font-size: 14px;
    padding: 0px 20px;
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    margin: 12px 0 20px 0 !important;
  }

  .siw-info a {
    position: unset;
    border-radius: 0px 0px 8px 8px;
    margin-bottom: 28px;
    padding: 20px;
    font-size: 12px;
    font-weight: 500;
  }

  .slider-title {
    width: auto;
    margin: 0 0 2.5rem 0;
  }

  .siw-img-loader {
    padding: 2rem !important;
  }
}

@media only screen and (max-width: 767px) {
  .slider-section {
    padding: 5rem 3rem;
  }
}

@media only screen and (max-width: 575px) {
  .slider-section {
    padding: 5rem 0.5rem;
    margin-top: 15vh;
  }

  .siw-image {
    /* max-height: 150px; */
    max-height: 385px;
  }

  .siw-info {
    padding: 40px 0 0 0;
    width: 100%;
    height: 300px;
  }
}

@media only screen and (max-width: 475px) {
  .siw-info {
    height: 350px;
  }
}
