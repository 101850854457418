

.sparKix-career-page {
  margin-top: 1rem;
  padding: 0rem 9rem;
}
.admin-sparKix-career-page{
  margin-top: 1rem;
  padding: 0rem 2rem;
}
.resumes-sparKix-career-page{
  margin-top: 1rem;
  padding: 0rem 2rem;
}
.sparKix-career-page .text-heading {
  font-family: Poppins;
  margin: 0 0 1.5rem;
}

.sparKix-career-page .text-paragraph {
  font-family: Poppins;
  margin: 0 0 1.5rem;
}

.sparKix-career-page-container {
}
.sparKix-career-page .sparKix-career-page-container .search-container {
  margin: 20px auto;
  max-width: 1200px;
  padding: 0 15px;
}

.search-container .box {
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
}
.select-container {
  min-width: 120px;
  max-width: 200px;
}
.apply-job-btn{
  
  border-radius: 10px;
    background-color: rgba(51, 51, 51, 0.05);
    border-radius: 8px;
    border-width: 0;
    color: #284F8D;
    cursor: pointer;
    display: inline-block;
    font-family: "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    list-style: none;
    margin: 0;
    padding: 5px 12px;
    text-align: center;
    transition: all 200ms;
    vertical-align: baseline;
    white-space: nowrap;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    border:1px solid #284F8D !important;
}

.apply-job-btn:hover {
  background-color: #284F8D !important;
  border:1px solid #284F8D !important;
  color: #FFFFFF !important;


}

.apply-job-btn-two{
  width:25%;
  textAlign:center;
    background-color: #284F8D;
  border-radius: 10px;
    border-radius: 8px;
    border-width: 0;
    color: white !important;
    cursor: pointer;
    display: inline-block;
    font-family: "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    list-style: none;
    margin: 0;
    padding: 10px 12px;
    text-align: center;
    transition: all 200ms;
    vertical-align: baseline;
    white-space: nowrap;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
}


.apply-job-btn-two:hover {
  background-color: transparent !important;
  border:1px solid #284F8D !important;
  transform: translateY(-1px);
  color: #284F8D !important;
}


@media (max-width: 768px) {
    .sparKix-career-page {
      margin-top: 1rem;
      padding: 0rem 1rem;
    }
    .apply-job-btn-two{
      width:50%;
      margin-bottom:1rem; 
    }
  }


/* --------------------------------------------------------------- Description Page ------------------------------------------------------ */
.spark-insights-job-description .description-page {
    background-color: #ffffff;
    border-radius: 10px;
    /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); */
    /* padding: 20px; */
    max-width: 100%;
    margin: 0 auto;
  }
  
  .spark-insights-job-description .title {
    font-size: 24px;
    color: #333333;
    /* margin-bottom: 10px; */
  }
  
  .spark-insights-job-description .description {
    font-size: 16px;
    color: #666666;
    line-height: 1.6;
  }

  .spark-insights-job-description .job-description ,
  .requirements ul {
    padding: 20px;
  }

  .spark-insights-job-description .spark-insights-job-description .job-description h2,
  .requirements h2 {
    background-color: #284F8D;
    color: #fff;
    padding: 10px;
    border-radius: 5px 5px 0 0;
    margin-top: 0;
  }
  .spark-insights-job-description .requirements .requirements-ulAndList {
    list-style-type: none;
    padding-left: 0;
  }
  
  .spark-insights-job-description .requirements .requirements-ulAndList li {
    margin-bottom: 10px;
  }






