.casestudy-section {
  min-height: auto;
  padding: 3rem 2rem;
  margin-top: 25vh;
  opacity: 0.8;
}

.casestudy-section h1 {
  font-family: "Poppins";
  color: white;
}

.casestudy-section p {
  color: white;
  padding: 1rem 0;
  max-width: 550px;
  font-size: 20px;
  font-family: "Poppins";
}

.casestudy-head {
  display: flex;
  justify-content: space-between;
}

.casestudy-head div {
  display: flex;
  gap: 1rem;
  padding: 2rem 1rem 0 0;
}

.cs-icon {
  font-size: 20px;
  color: white;
  cursor: pointer;
  transform: 0.2s;
}

.cs-icon:active {
  color: #1b4f92;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide {
  width: 100%;
}

.cs-content {
  width: 100%;
  height: 80vh;
  border-radius: 1.5rem;
  display: flex;
  overflow: hidden;
  /* background-color: #EAEAEA; */
  cursor: grab;
}

.cs-left {
  width: 20%;
}
.cs-right {
  width: 80%;
}

.cs-left {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 3rem 1rem;
}

.cs-left h5 {
  margin: 0 0 1rem;
  font-weight: bold;
}

.cs-left p {
  font-size: 15px !important;
  font-weight: 400;
}

.cs-right img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@media only screen and (max-width: 1200px) {
  .cs-left h5 {
    margin: 0;
    font-size: 18px;
  }
  .cs-left p {
    font-size: 14px !important;
  }
}

@media only screen and (max-width: 1100px) {
  .cs-content {
    flex-direction: column-reverse;
  }
  .cs-left {
    width: 100%;
    height: 50%;
  }
  .cs-right {
    width: 100%;
    height: 50%;
  }
  .swiper-slide {
    width: 60%;
  }
  .cs-left h5 {
    font-size: 20px;
  }
  .cs-left p {
    font-size: 15px !important;
  }
}

@media only screen and (max-width: 767px) {
  .casestudy-section {
    padding: 3rem 1rem;
  }

  .swiper-slide {
    width: 100%;
  }

  .swiper-slide img {
    border-radius: 4px;
  }

  .cs-left h5 {
    font-size: 18px;
  }
  .cs-left p {
    font-size: 14px !important;
  }

  .casestudy-head {
    display: block;
  }

  .casestudy-section p {
    padding: 1rem 0 0;
  }

  .casestudy-head div {
    display: flex;
    justify-content: center;
    gap: 1rem;
    padding: 0 1rem 1rem;
  }
}

@media only screen and (max-width: 575px) {
  .casestudy-section {
    padding: 3rem 1rem;
    margin: 10vh 0;
  }
  .casestudy-section{
    text-align: center;
  }
}
