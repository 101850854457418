.sidebar {
  margin-top: 3.5rem;
  position: fixed;
  top: 0;
  left: -250px;
  width: 250px;
  height: 100%;
  background-color: #ffffff;
  transition: left 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 200 !important;
}

.sidebar.open {
  left: 0;
}

.sidebar-toggle {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  color: #fff;
}

.sidebar-nav {
  list-style-type: none;
  padding: 0;
}

.sidebar-nav li {
  padding: 15px;
  cursor: pointer;
  color: #284f8d;
  font-weight: bold;
}

.sidebar-nav li:hover,
.sidebar-nav li:hover a {
  background-color: #284f8d;
  color: #fff !important;
  text-decoration: none;
}

.sidebar-nav li a {
  color: inherit;
  text-decoration: none;
}

.sidebar-nav li a:hover {
  color: #fff;
  text-decoration: none;
}

.icon {
  font-size: 1.5rem;
}

.nav-icon {
  margin-right: 10px;
}
.sidebar.closed {
  width: 50px;
}
.sidebar-nav li.icon-only {
  padding: 15px 0;
}
.logout-button-bottom {
  margin-top: 10px;
  margin-bottom: 10px;
}
.side-bar-link-style{
  color: #284f8d!important;
  text-decoration: none!important;
}