.spark-job-card {
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
  align-items: center;
}

.spark-job-card .card-header {
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 10px;
}

.spark-job-card .card-body {
  display: flex;
  flex-direction: column;
}

.spark-job-card .job-salary-info {
  margin-bottom: 10px;
  font-size: 15px;
  color:#64656A !important;
}
.spark-job-card .job-date-info {
  margin-bottom: 10px;
  font-size: 13px;
  color:#64656A !important;
}
.spark-job-card .card-header-job-type{
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 10px;
}

.location {
  margin-bottom: 20px;
}

.btn-primary {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.btn-primary:hover {
  background-color: #0056b3;
}
