:root {
  --primary-color: #284F8D;
  --bg-primary: #f0f0f0; 
  --gradient-color-1: #ff7e5f; 
  --gradient-color-2: #feb47b; 
}
body {
  margin: 0;
  font-family: "Poppins" !important;
  -webkit-overflow-scroll: touch !important;
  overflow: auto !important; 
  
}

 
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 25px;
}
::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 25px;
}

