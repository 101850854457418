.footer-main {
  padding: 4rem 2rem 0rem;
  color: white;
  margin-top: 20vh;
  opacity: 0.95;
  position: relative;
}

.footer-brand {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  text-decoration: none;
}

.footer-brand img {
  width: 200px;
  height: 100%;
  object-fit: contain;
  border-radius: 5px;
  cursor: pointer;
}

.footer-logo {
  margin: 0;
  font-weight: bold;
  color: white;
  font-family: "Poppins";
}

.footer-wrap {
  padding: 0 4rem 2rem 0;
}

.footer-wrap p {
  margin: 1.5rem 0;
  color: white;
  font-family: "Poppins";
  font-weight: 500;
  font-size: 14px;
}

.footer-wrap button {
  padding: 0.625rem 16px;
  border-radius: 4px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  font-family: "Poppins";
  font-size: 14px;
  width: 178px;
  transition: 0.3s;
  color: white;
  background-color: transparent;
  opacity: 1 !important;
  z-index: +1000;
  font-weight: 500;
}

.footer-wrap button:hover {
  opacity: 0.85;
  gap: 20px;
  background-color: #0077b6;
  border-color: transparent !important;
}

.footer-boder {
  border-bottom: 1px solid white;
}

.footer-copywrite {
  text-align: center;
  padding: 0.8rem;
  color: rgba(255, 255, 255, 0.87);
}

.footer-copywrite a {
  color: rgba(255, 255, 255, 0.87);
  font-weight: bold;
}

.footer-wrap2 h1 {
  color: white;
  font-family: "Poppins";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  padding: 20px 0;
  border-top: 1px solid lightgray;
  transition: 0.3s;
}

.footer-wrap2 p {
  margin-bottom: 16px;
  color: white;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  font-family: "Poppins";
  transition: 0.2s;
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
  margin: 0 0 0.7rem;
}

.footer-wrap2:hover h1 {
  border-top: 1px solid #0077b6;
  /* opacity: 0.5; */
}

.footer-wrap2 p:hover {
  color: #0077b6;
  /* opacity: 0.5; */
  cursor: pointer;
}

.footer-icon {
  margin-bottom: 16px;
}

.footer-icons-wrap {
  display: flex;
  align-items: center;
  gap: 2rem;
  margin: 1.5rem 0 0;
}

.f-icon {
  padding: 0 2rem 0 0rem;
  color: white;
  font-size: 23px;
}

.f-divider {
  border-right: 2px solid white;
}

.footer-contact {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.footer-contact div {
  padding: 0.625rem 16px;
  border-radius: 4px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  font-family: "Poppins";
  font-size: 14px;
  color: white;
  background-color: transparent;
  opacity: 1 !important;
  z-index: +1000;
  font-weight: 500;
  border: 1px solid white;
}

.spark-chatbot-image {
  position: fixed;
  bottom: 60px;
  right: 30px;
  width: 47px;
  height: 47px;
  cursor: pointer;
  padding: 2px;
  border-radius: 10px;
  background: #284f8d;
}
.spark-chatbot-image2 {
  position: fixed;
  bottom: 60px;
  right: 30px;
  width: 50px;
  height: 50px;
  cursor: pointer;
}

@keyframes rotateCross {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.sprak-cros-container {
  background-color: #284f8d;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 50%;
}

.sprak-cros-container.rotate {
  animation: rotateCross 0.5s forwards;
}

@media only screen and (max-width: 991px) {
  .footer-wrap {
    padding: 0 2rem 2rem 0;
  }
}

@media only screen and (max-width: 767px) {
  .footer-wrap {
    padding: 0 0rem 2rem 0;
  }
  .sprak-cros-container {
    width: 40px;
    height: 40px;
  }
}

@media only screen and (max-width: 575px) {
  .footer-main {
    padding: 4rem 1rem 0rem;
    margin-top: 15vh;
  }
}

@media only screen and (max-width: 475px) {
  .footer-main {
    padding: 4rem 0.5rem 0rem;
  }
}

@media only screen and (max-width: 575px) {
  .spark-chatbot-image {
    position: fixed;
    bottom: 15px;
    right: 5px;
    width: 50px;
    height: 50px;
    cursor: pointer;
  }
  .spark-chatbot-image2 {
    position: fixed;
    bottom: 15px;
    right: 5px;
    width: 50px;
    height: 50px;
    cursor: pointer;
  }
}
