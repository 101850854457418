/* .aboutUs-main {} */

.aboutUs-main h1 {
    font-family: 'Poppins';
    margin: 0 0 3rem;
}

.aboutUs-main p {
    font-family: 'Poppins';
    margin: 0 0 1.5rem;
}

.au-www {
    padding: 4rem;
}

.au-industries{
    padding: 2rem 4rem;
}

.au-whyus {
    background-color: #EEEEEE;
    padding: 2rem 4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.au-whyus img {
    height: 480px;
}

.au-whyus-left {
    width: 700px;
}

.au-ourcv {
    padding: 4rem;
}

.au-ourcv p {
    font-size: 18px;
    margin: 0 0 3rem;
}

.au-container{
    position: relative;
}

.au-container .row .col{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.au-ourcv-card {
    background-color: white;
    height: auto;
    width: 440px;
    border-radius: 30px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
    margin-top: 2rem;
    padding: 1.6rem;
    position: relative;
}

.au-ourcv-card h6 {
    font-family: 'Poppins';
    margin: 0 0 1rem;
}

.au-ourcv-card p {
    font-size: 16px;
    margin: 1.5rem 0;
}

.au-card-arrow-right {
    position: absolute;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid #7C7C7C;
    top: 2rem;
    right: -0.7rem;
}

.au-card-arrow-left {
    position: absolute;
    width: 0; 
    height: 0; 
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent; 
    border-right: 10px solid #7C7C7C; 
    top: 2rem;
    left: -0.7rem;
  
}

.au-divider{
    background-color: #d7e4ed;
    height: 76%;
    width: 5px;
    position: absolute;
    top: 4rem;
    left: 50%;
    right: 50%;
}

.au-divider2{
    /* height: 76%; */
    width: 5px;
    position: absolute;
    top: 4rem;
    left: 50%;
    right: 50%;
    transition: 0.7s;
}

.au-card-circle-right{
    height: 40px;
    width: 40px;
    background-color: white;
    position: absolute;
    top: 3rem;
    right: -1.4rem;
    border-radius: 50%;
    z-index: +1;
    transition: 0.3s;
    border: 5px solid white;
}

.au-card-circle-left{
    height: 40px;
    width: 40px;
    background-color: white;
    position: absolute;
    top: 3rem;
    left: -1rem;
    border-radius: 50%;
    z-index: +1;
    transition: 0.3s;
    border: 5px solid white;
}

@media only screen and (max-width: 1200px) {
    .au-ourcv-card {
        width: 380px;
    }
}

@media only screen and (max-width: 991px) {
    .au-whyus {
        padding: 4rem;
        flex-direction: column;
        align-items: start;
    }

    .au-whyus-left {
        width: 100%;
    }

    .au-whyus img {
        height: 280px;
    }

    .au-whyus-right{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .au-divider{
        left: 0%;
        right: auto;
    }
    
    .au-divider2{
        left: 0%;
        right: auto;
    }

    .au-card-circle-right{
        top: 3rem;
        right: auto;
        left: -1rem;
    }
    
    .au-card-circle-left{
        top: 3rem;
        left: -1rem;
    }

    .au-card-arrow-right {
        border-left: none;
        border-right: 10px solid #7C7C7C; 
        top: 2rem;
        right: auto;
        left: -0.7rem;
    }

    .au-ourcv-card {
        width: 90%;
    }
    
}

@media only screen and (max-width: 767px) {
    .au-www {
        padding: 4rem 2rem;
    }
    .au-whyus {
        padding: 4rem 2rem;
    }
    .au-industries{
        padding: 4rem 2rem;
    }
    .au-ourcv {
        padding: 4rem 2rem;
    }
    .au-container{
        max-width: 100% !important;
    }
}

@media only screen and (max-width: 650px) {
    .au-ourcv-card {
        width: 80%;
    }
}

@media only screen and (max-width: 475px) {
    .au-www {
        padding: 2rem 1rem;
    }
    .au-whyus {
        padding: 3rem 1rem;
    }
    .au-industries{
        padding: 3rem 1rem;
    }
    .au-ourcv {
        padding: 3rem 1rem;
    }
    .au-container{
        max-width: 95% !important;
    }
    .au-ourcv-card {
        width: 98%;
        padding: 1.6rem 0.5rem;
        margin-left: 1.6rem;
    }
}