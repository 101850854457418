.ourprocess-section {
    /* min-height: 100vh; */
    padding: 3rem 2rem;
    margin-top: 25vh;
}

.ourprocess-section h1 {
    margin: 0 0 3rem 0;
    font-family: 'Poppins';
}

.op-left-img {
    height: 85vh;
    width: 100%;
    object-fit: contain;
}

.op-section-wrap {
    position: relative;
}

.op-sec-line {
    position: absolute;
    top: 0;
    left: 1.5rem;
    border-style: dashed;
    border-width: 1px;
    border-color: lightgray;
    /* height: calc(100% + 4.5rem); */
    height: 95%;
    margin-top: 0.75rem;
    left: 1.5rem;
}

.op-section-box {
    position: relative;
    display: flex;
    margin-bottom: 3.5rem;
}

.op-box-icon {
    vertical-align: top;
    width: 3rem;
    height: 3rem;
    display: inline-block;
    margin-top: 10px;
    position: relative;
}

.op-icon-div {
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 45px;
    width: 45px;
    position: absolute;
    top: 0;
    left: 2px;
}

.op-sec-details {
    width: 520px;
    margin-left: 1.5rem;
    display: inline-block;
    vertical-align: top;
}

.op-sec-details span {
    --tw-text-opacity: 1;
    color: rgb(104 117 128/var(--tw-text-opacity));
    margin-bottom: 0.5rem;
    font-size: .875rem;
    line-height: 1.25rem;
    font-weight: 700;
    font-family: 'Poppins';
}

.op-sec-details h3 {
    --tw-text-opacity: 1;
    color: rgb(27 31 34/var(--tw-text-opacity));
    font-weight: 500;
    margin-bottom: 0.5rem;
    /* font-size: 1.875rem;
    line-height: 2.375rem; */
    font-family: 'Poppins';
}

.op-sec-details p {
    --tw-text-opacity: 1;
    color: rgb(61 71 81/var(--tw-text-opacity));
    font-size: 1rem;
    line-height: 1.5rem;
    font-family: 'Poppins';
}


.op-sec-line2 {
    margin-top: 1.5rem;
    margin-left: 1.5rem;
    border-style: dashed;
    width: 3rem;
    position: absolute;
    border-width: 1px;
    border-color: lightgray;
}

.op-btn-div {
    position: relative;
    margin-left: 3.6rem;
}

.op-button {
    padding: 0.625rem 32px;
    border-radius: 4px;
    border: none;
    margin: 0 0 0 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    font-family: 'Poppins'; 
    width: 223px;
    transition: 0.3s;
}

.op-button:hover {
    opacity: 0.85;
    gap: 20px;
}

@media only screen and (max-width: 991px) {
    .op-left-img {
        display: none;
    }

    .op-sec-right{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .op-sec-details {
        width: 420px;
        margin-left: 1.5rem;
        display: inline-block;
        vertical-align: top;
    }
}

@media only screen and (max-width: 575px) {
    .op-sec-details {
        width: auto;
        margin-left: 3rem;
        display: inline-block;
        vertical-align: top;
    }

    .ourprocess-section {
        padding: 2rem 1rem;
        margin-top: 15vh;
    }
}

@media only screen and (max-width: 475px) {
    .ourprocess-section {
        padding: 2rem 0.5rem;
    }
}