.spark-spinner {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  
  .spark-spinner .spinner {
    width: 56px;
    height: 56px;
    display: grid;
    border-radius: 50%;
    -webkit-mask: radial-gradient(farthest-side, #0000 40%, #284f8d 41%);
    background: linear-gradient(
          0deg,
          rgba(40, 79, 141, 0.5) 50%,
          rgba(40, 79, 141, 1) 0
        )
        center/4.5px 100%,
      linear-gradient(
          90deg,
          rgba(40, 79, 141, 0.25) 50%,
          rgba(40, 79, 141, 0.75) 0
        )
        center/100% 4.5px;
    background-repeat: no-repeat;
    animation: spinner-d3o0rx 1s infinite steps(12);
  }
  
  .spark-spinner .spinner::before,
  .spark-spinner .spinner::after {
    content: "";
    grid-area: 1/1;
    border-radius: 50%;
    background: inherit;
    opacity: 0.915;
    transform: rotate(30deg);
  }
  
  .spark-spinner .spinner::after {
    opacity: 0.83;
    transform: rotate(60deg);
  }
  
  @keyframes spinner-d3o0rx {
    100% {
      transform: rotate(1turn);
    }
  }
  