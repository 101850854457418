.job-select{
    
}
.job-select .select-heading{
    font-family: Poppins;
    font-weight: 500 !important;

}

.autocomplete-wrapper {
    position: relative;
  }
  
  .suggestions-list {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    border: 1px solid #ccc;
    background: white;
    list-style: none;
    padding: 0;
    margin: 0;
    z-index: 1;
  }
  
  .suggestions-list li {
    padding: 8px;
    cursor: pointer;
  }
  
  .suggestions-list li:hover {
    background-color: #e9e9e9;
  }