.spark-clients-slider {
  margin-top: 2rem !important;
}

.spark-clients-slider .spark-client-section-header h1 {
  font-size: 40px;
  font-weight: 700;
  color: #284f8d;
  text-align: center;
  position: relative;
  font-family: Poppins;

}

.spark-clients-slider .spark-client-section-header2 h1 {
  font-size: 40px;
  font-weight: 500;
  color: #00000;
  text-align: center;
  position: relative;
  font-family: Poppins;

}

.spark-clients-slider .spark-client-section-header p {
  text-align: center;
  margin: auto;
  font-size: 15px;
  padding-bottom: 60px;
  color: #556877;
  width: 50%;
}


.spark-clients-slider .spark-advance-features-header h1 {
  font-size: 40px;
  font-weight: 700;
  color: #284f8d;
  text-align: center;
  position: relative;
  font-family: Poppins;
}
.spark-advance-features-header2{
  font-size: 40px;
  font-weight: 500;
  color: #00000;
  text-align: center;
  position: relative;
  font-family: Poppins;
}
.spark-advance-features-header2 p{
  text-align: center;
  margin: auto;
  font-size: 15px;
  padding-bottom: 60px;
  color: #556877;
  width: 50%;
}
.spark-clients-slider .spark-advance-features-header p {
  text-align: center;
  margin: auto;
  font-size: 15px;
  padding-bottom: 20px;
  color: #556877;
  width: 50%;
}

.spark-clients-slider .spark-client-section-header2 p {
  text-align: center;
  margin: auto;
  font-size: 15px;
  padding-bottom: 60px;
  color: #556877;
  width: 50%;
}
#spark-clients {
  padding: 10px 0;
}
#spark-clients .clients-wrap {
  margin-bottom: 10px;
}

#spark-clients .client-logo {
  padding: 64px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden;
  background: #fff;
  height: 160px;
}

#spark-clients img {
  transition: all 0.4s ease-in-out;
}

.spark-clients-slider .slider-container {
  padding: 0rem 0.5rem;
}
.spark-clients-slider .slick-slider {
  padding: 0rem 0.5rem;
}

.spark-clients-slider .slider-container .slick-arrow.slick-next,
.spark-clients-slider .slider-container .slick-arrow.slick-prev {
  background-color: #284f8d;
  height: 25px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  height: 30px;
  width: 30px;
}

.spark-clients-slider .slick-arrow.slick-next:hover,
.spark-clients-slider .slick-arrow.slick-prev:hover {
  background-color: #284f8d;
  opacity: 1;
}

.slick-prev::before,
.slick-next::before {
  display: none;
}

.spark-arrows {
  height: 20px;
  width: 20px;
  margin: 1px;
}


.spark-slider-images-slick{
  max-width:100px;
  width:100%;
  height:auto;
}


@media only screen and (max-width: 991px) {
    .spark-clients-slider {
        padding:.2rem 1rem;
    }
    .spark-slider-images-slick{
      min-width:70px;
    }
    .spark-clients-slider .spark-advance-features-header p {
      width:80% !important;
    }

  }

  