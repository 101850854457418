.spark-tech-faq {
  max-width: 70%;
  margin: 0 auto;
  font-family: Arial, sans-serif;
}
.spark-tech-faq-heading {
  font-family: Poppins;
  font-size: 40px;
  font-weight: 600;
  color: var(--primary-color);
  font-weight: 700;
  text-align: center;
  margin-bottom: 5rem;
}
.spark-accordion-body {
  font-size: 14px !important;
}
.spark-accordion-header {
  font-size: 16px !important;
}
.accordion-button:not(.collapsed) {
  background-color: none !important;
}
.spark-accordion-heading {
  font-weight: bold !important;
  font-size: 16px !important;
}

.spark-accordion-item .accordion-button:not(.collapsed) {
  color: inherit;
  background-color: transparent;
  box-shadow: none;
}

.spark-accordion-item .accordion-button::after {
  color: inherit;
}

.spark-accordion-item .accordion-button {
  color: inherit;
  background-color: transparent;
}

.spark-accordion-body {
}
.accordion-button {
  font-weight: 600 !important;
  font-size: 14px !important;
  color: inherit !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

.accordion-button::after {
  color: inherit !important;
}

.accordion-button:not(.collapsed) {
  color: inherit !important;
  background-color: transparent !important;
}
@media only screen and (max-width: 767px) {
  .spark-tech-faq {
    max-width: 100%;
    margin: auto;
    font-family: Arial, sans-serif;
    padding: 0 .5rem;
  }
  .spark-tech-faq-heading {
    margin-bottom: 2rem;
  }
}
