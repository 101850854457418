
.spark-admin-navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff; 
    height: 60px;
    display: flex;
    align-items: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
    z-index: 1; 
  }
  .spark-admin-navbar-toggle {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 10px;
    display: flex;
    align-items: center;
  }
  
  .spark-admin-logo {
    width: 150px; 
    height: auto; 
    margin-right: 10px; 
  }
  
