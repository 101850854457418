.sparkadmin-dashboard-job-admin-container{
    margin: 20px auto;
    max-width: 1200px;
    padding: 0 15px;
}

.sparkadmin-dashboard-job-admin-container .select-heading{
    font-family: Poppins;
    font-weight: 500 !important;

}
.sparkadmin-dashboard-job-admin-container .job-submit-container{
  text-align: right;

}
.sparkadmin-dashboard-job-admin-container .job-submit-button{
background-color:#284F8D !important;
width: 20%;
}   