.services-section {
  padding: 7rem 2rem 13rem;
  position: relative;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.services-section h1 {
  color: black;
  margin: 0 0 3.5rem 0;
  font-family: "Poppins";
}

.scene {
  perspective: 1000px;
  -webkit-perspective: 1000px;
  overflow: hidden;
  backdrop-filter: blur(12px);
  opacity: 0.8;
}

.services-section-card {
  height: 251px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}

.card1 {
  border-right: 1px dashed #00000027;
  border-bottom: 1px dashed #00000027;
  border-top-left-radius: 12px;
}

.card2 {
  border-bottom: 1px dashed #00000027;
  border-right: 1px dashed #00000027;
}

.card3 {
  border-bottom: 1px dashed #00000027;
  border-top-right-radius: 12px;
}

.card4 {
  border-right: 1px dashed #00000027;
  border-bottom: 1px dashed #00000027;
  border-bottom-left-radius: 12px;
}

.card5 {
  border-bottom: 1px dashed #00000027;
  border-right: 1px dashed #00000027;
}

.card6 {
  border-bottom: 1px dashed #00000027;
  border-bottom-right-radius: 12px;
}

.services-section-card:focus {
  outline: 0;
}

.services-section-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  -webkit-transition: -webkit-transform 0.6s;
  transition: transform 0.6s;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  /* backface-visibility: hidden;
  -moz-backface-visibility: hidden; */

  /* -webkit-transition: -webkit-transform 0.6s;
  -webkit-transform-style: preserve-3d;
  -webkit-backface-visibility: hidden; */
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 10px !important;
}

.flip-card-front {
  z-index: 2;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-back {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
  z-index: 1;
  display: flex;
  align-items: center;
  color: white;
}

.f-card-ul div {
  display: flex;
  gap: 0.7rem;
  margin: 0 0 0.5rem;
  font-size: 15px;
}

.card-icon {
  font-size: 18px;
  padding-top: 0.1rem;
}

.services-section-card img {
  width: 66px;
  height: 66px;
  border: none;
}

.services-section-card div {
  text-align: left;
  margin: 0;
  padding: 0;
}

.services-section-card h5 {
  font-size: 18px !important;
  line-height: 20px;
  letter-spacing: -0.28px;
  margin-bottom: 5px;
  color: white;
  font-weight: bold;
  /* opacity: 0.5; */
  font-family: "Poppins";
}

.services-section-card h5 span {
  font-size: 16px;
}

.services-section-card p {
  font-size: 17px;
  line-height: 24px;
  color: white;
  margin: 0;
  padding: 0;
  text-align: left;
  font-family: "Poppins";
}

@media only screen and (max-width: 1260px) {
  /* .flip-card-back ul {
    margin: 0;
  }

  .flip-card-back ul li {
    font-size: 13px;
  } */
  .f-card-ul div {
    margin: 0 0 0;
    font-size: 13px;
  }

  .card-icon {
    font-size: 16px;
    padding-top: 0.1rem;
  }
}

@media only screen and (max-width: 991px) {
  .services-section-card {
    height: 200px;
    gap: 1rem;
  }

  .flip-card-front,
  .flip-card-back {
    padding: 32px 30px !important;
  }

  .card1 {
    border-right: 1px dashed #00000027;
    border-bottom: 1px dashed #00000027;
    border-top-left-radius: 0;
  }

  .card2 {
    border-right: none;
  }

  .card3 {
    border-left: 1px dashed #00000027;
    border-bottom: 1px dashed #00000027;
    border-top-right-radius: 0;
  }

  .card4 {
    border-bottom-left-radius: 0;
  }

  .card5 {
    border-bottom: 1px dashed #00000027;
    border-right: 0;
  }

  .card6 {
    border-left: 1px dashed #00000027;
    border-bottom: 1px dashed #00000027;
    border-bottom-right-radius: 0;
  }
}

@media only screen and (max-width: 767px) {
  .services-section-card {
    gap: 1rem;
  }

  .flip-card-front,
  .flip-card-back {
    padding: 32px 40px !important;
  }

  .card1 {
    border-right: 0;
    border-bottom: 1px dashed #00000027;
  }

  .card3 {
    border-right: 0;
    border-left: none;
    border-bottom: 1px dashed #00000027;
  }

  .card4 {
    border-right: none;
    border-bottom: 1px dashed #00000027;
  }

  .card5 {
    border-bottom: 1px dashed #00000027;
  }

  .card6 {
    border-left: 0;
    border-bottom: 0;
  }

.container-fluid .row h1{
text-align: center;
}

}

@media only screen and (max-width: 475px) {
  .services-section {
    padding: 7rem 0.5rem 7rem;
    height: auto;
  }

  .services-section-card {
    gap: 1rem;
  }

  .flip-card-front,
  .flip-card-back {
    padding: 32px 20px !important;
  }

  .services-section-card h5 {
    font-size: 18px;
  }

  .services-section-card p {
    font-size: 15px;
  }
}
