.job-admin-container{
    margin: 20px auto;
    max-width: 1200px;
    padding: 0 15px;
}

.job-admin-container .select-heading{
    font-family: Poppins;
    font-weight: 500 !important;

}
.job-admin-container .job-submit-container{
  text-align: right;

}
.job-admin-container .job-submit-button{
background-color:#284F8D !important;
width: 20%;
}   

.job-submit-container .apply-job-submit-button{
  width:20%;
  textAlign:center;
    background-color: #284F8D;
  border-radius: 10px;
    border-radius: 8px;
    border-width: 0;
    color: white !important;
    cursor: pointer;
    display: inline-block;
    font-family: "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    list-style: none;
    margin: 0;
    padding: 10px 12px;
    text-align: center;
    transition: all 200ms;
    vertical-align: baseline;
    white-space: nowrap;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
}


.apply-job-submit-button:hover {
  background-color: transparent !important;
  border:1px solid #284F8D !important;
  transform: translateY(-1px);
  color: #284F8D !important;
}


@media (max-width: 768px) {
  .job-submit-container .apply-job-submit-button{
    text-align: center;
      width:50%;
    }
    .job-admin-container .job-submit-container{
      text-align: center !important;;
    
    }
  }