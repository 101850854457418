.schedulecall-right {
  min-height: 100vh;
  padding: 3.5rem 1rem;
  color: white;
}

.schedulecall-right p {
  font-size: 1.5rem;
  line-height: 2rem;
  text-align: center;
  margin-bottom: 3.5rem;
  font-family: "Poppins";
}

.schedulecall-right figure {
  max-width: 10rem;
  height: 3.5rem;
  margin-top: 1.5rem;
  box-sizing: content-box;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.schedulecall-right figure img {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  color: transparent;
  padding: 0.25rem;
  object-fit: contain;
  max-width: 100%;
}

.schedulecall-left {
  height: 100%;
  display: flex;
  justify-content: center;
  /* padding: 3.5rem 1rem; */
  padding: 1rem 3rem;
}

.schedulecall-left-wrap {
  width: 650px;
  padding: 16px;
  position: relative;
}

.schedulecall-brand {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  text-decoration: none;
}

.schedulecall-brand img {
  width: 36px;
  height: 36px;
  object-fit: contain;
  border-radius: 50%;
}

.schedulecall-logo {
  margin: 0;
  font-weight: bold;
  color: black;
  font-family: "Poppins";
}

.schedulecall-left-wrap p {
  font-size: 1.125rem;
  line-height: 1.75rem;
  /* padding: 2.5rem 0; */
  padding: 1rem 0;
  font-family: "Poppins";
  font-weight: bold;
}

.sc-input-wrap {
  margin: 0 0 0.2rem;
  position: relative;
}

.sc-input-wrap p {
  font-size: 1rem;
  line-height: 1.5rem;
  padding: 0;
  margin: 0 0 0.3rem 0;
}

.sc-input-wrap input,
.sc-input-wrap textarea {
  border-radius: 4px !important;
  border-color: #c2c7cc;
  padding: 0.65rem;
  font-family: "Poppins";
}

.sc-input-wrap textarea {
  height: 105px;
}

.sc-input-wrap input:hover,
.sc-input-wrap textarea:hover {
  border-color: #687580 !important;
}

.sc-input-wrap input::placeholder,
.sc-input-wrap textarea::placeholder {
  font-family: "Poppins";
}

.form-control:focus {
  box-shadow: none;
  border: 2px solid #687580;
}

.sc-input-wrap label {
  --tw-text-opacity: 1;
  color: rgb(221 54 54 / var(--tw-text-opacity));
  font-size: 0.75rem;
  line-height: 1.125rem;
  font-family: "Poppins";
}

.sc-input-icon {
  position: absolute;
  right: 1rem;
  top: 45%;
  color: #dc3636;
}

.sc-input-wrap input.active,
.sc-input-wrap textarea.active {
  border: 2px solid #dc3636 !important;
}

.sc-input-wrap button {
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin: 2.5rem 0 0;
  position: absolute;
  bottom: 1.6rem;
  right: -10.5rem;
  font-family: "Poppins";
}

.css-13cymwt-control {
  border-radius: 4px !important;
  border-color: #c2c7cc !important;
  padding: 4.9px 2px;
  min-height: 47px;
  max-height: 100%;
}

.active2 .css-13cymwt-control,
.active2 .css-t3ipsp-control {
  border: 2px solid #dc3636 !important;
}

.css-13cymwt-control:hover {
  border-color: #687580 !important;
}

.css-1jqq78o-placeholder,
.css-wsp0cs-MultiValueGeneric {
  font-family: "Poppins";
}

.css-t3ipsp-control {
  border-radius: 4px !important;
  border: 2px solid #687580 !important;
  box-shadow: none !important;
  padding: 4.9px 2px;
  min-height: 47px;
  max-height: 100%;
}

.css-13cymwt-control:focus {
  border-color: #c2c7cc !important;
  box-shadow: none !important;
}

@media only screen and (max-width: 1300px) {
  .schedulecall-left {
    justify-content: center;
    padding: 3.5rem 1rem;
  }

  .sc-input-wrap button {
    margin: 1.5rem 0 0;
    position: static;
  }
}

@media only screen and (max-width: 991px) {
  .schedulecall-left {
    padding: 1rem;
  }

  .schedulecall-right {
    min-height: 100%;
    padding: 1.5rem 1rem;
  }
}

@media only screen and (max-width: 575px) {
  .schedulecall-left {
    padding: 1rem 0.5rem;
  }

  .schedulecall-right {
    min-height: 100%;
    padding: 1.5rem 0.5rem;
  }

  .css-1p3m7a8-multiValue {
    max-width: 400px;
  }
}

@media only screen and (max-width: 475px) {
    .css-1p3m7a8-multiValue {
      max-width: 300px;
    }
  }
