
  
  .content {
    flex: 1;
    padding: 10px;
    padding-top:4rem;
    transition: margin-left 0.3s ease;
  }
  .content-mobile-screen{
    padding: 10px;
    padding-top:4rem;
    transition: margin-left 0.3s ease;
  }
  
  .content.open {
    margin-left: 250px;
  }
  
  .flex-column-button-logout {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height:93vh;
  }
  .logout-button-bottom{
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    
  }
 