.spark_ai_services_hero-section {
  position: relative;
  padding: 50px 0;
  /* background-image: linear-gradient(to top, #fbc2eb 0%, #a6c1ee 100%); */
  /* background-image: linear-gradient(to top, #fdcbf1 0%, #fdcbf1 1%, #e6dee9 100%); */
  /* background-image: linear-gradient(to top, #a8edea 0%, #fed6e3 100%); */
  background-image: linear-gradient(-20deg, #e9defa 0%, #fbfcdb 100%);
}


.spark_ai_services_hero-section .welcome-text {
  font-size: 2.5em;
  color: transparent;
  font-weight: bold;
  /* text-align: justify; */
  text-justify: inter-word;
  


  /* background-color: #284f8d; */
  background-image: linear-gradient(
    43deg,
    #284f8d 0%,
    #c850c0 46%,
    #ffcc70 100%
  );

  -webkit-background-clip: text;
  background-clip: text;
  text-shadow: 2px 2px 4px rgba(0,0,0,0)
}
.spark_ai_services_hero-section .welcome-paragraph {
  font-size: 1.4rem;
  color: transparent;
  font-weight: 500;
  background: linear-gradient(to right, #6a82fb, #fc5c7d);
  -webkit-background-clip: text;
  background-clip: text;
}

.spark_ai_services_hero-section p {
  font-size: 1.5rem;
  color: #ffffff;
}

.spark_ai_services_hero-section .chatbot {
  margin-bottom: 20px;
}

.spark_ai_services-img-fluid {
  float: right;
  width: 70%;
  height: 100%;
  /* animation: float 3s infinite ease-in-out; */
}

@keyframes float {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}

@media (max-width: 768px) {
  .spark_ai_services-img-fluid {
    float: center;
    width: 100%;
    height: 100%;
    /* animation: float 3s infinite ease-in-out; */
  }
}

/* .spark_ai_services_hero-section {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 50px;
    background: linear-gradient(to right, rgba(106, 130, 251, 0.5), rgba(252, 92, 125, 0.2)); 

    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1); 
  }
  

  
  .spark_ai_services_hero-sectio .content {
    flex: 1;
  }
  .spark_ai_services_hero-section .content h1{
    color: #284F8D !important;
    z-index: 1;
    position: relative;
    font-weight: bold

  }
  .spark_ai_services_hero-section  .welcome-text {
    font-size: 3rem;
    color: #ffffff; 
  }
  
  @keyframes fadeInUp {
    from {
      transform: translateY(20px);
    }
    to {
      transform: translateY(0);
    }
  }
  
 .spark_ai_services_hero-section p {
    font-size: 1.5rem;
  
  }
  
  .spark_ai_services_hero-section .chatbot img {
    width: 200px;
    height: auto;
    animation: float 3s infinite ease-in-out; 
  }
  

  @media (max-width: 768px) {
    .spark_ai_services_hero-section .hero-section {
      flex-direction: column;
    }
  
    .spark_ai_services_hero-section .content,
    .spark_ai_services_hero-section .chatbot {
      max-width: 100%;
      padding-right: 0;
    }
  
    .spark_ai_services_hero-section.spark_ai_services_hero-section .content {
      order: 2;
      text-align: center;
    }
  
   .spark_ai_services_hero-section .chatbot {
      order: 1;
      margin-bottom: 20px;
    }
  } */
