.bs-scroll-height {
  --bs-scroll-height: "100px";
}

.custom-navbar {
  padding: 0.7rem 2rem;
  background-color: transparent !important;
  transition: .3s !important; 

}

.spark-website-sticky {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background: white !important;
  transition: .6s !important;
}

.navbar-brand {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}

.navbar-brand img {
  width: 200px;
  height: 100%;
  object-fit: contain;
}

.nav-logo {
  margin: 0;
  font-weight: bold;
  color: black;
  font-family: "Poppins";
}

.nav-link {
  padding: 0.55rem 1.3rem 0 !important;
  font-weight: 400;
  font-family: "Poppins";
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  color: black;
}

.services-popup-navbar-link:hover {
  color: #1B4F92;
  font-weight: 600;

}



.popup-navbar-link{
  position: relative;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background: #1B4F92;
    transform: translateX(-10%);
    transition: transform 0.3s ease;
    visibility: hidden;
  }

  &:hover:before {
    transform: translateX(0);
    visibility: visible;
  }
}
.nav-link span{
  position: relative;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background: #1B4F92;
    transform: translateX(-100%);
    transition: transform 0.3s ease;
    visibility: hidden;
  }

  &:hover:before {
    transform: translateX(0);
    visibility: visible;
  }
}

.nav-link:hover {
  color: #1B4F92;
}

.nav-link-icon {
  font-size: 12px;
  padding: 0.2rem 0 0 0;
}

.nav-button {
  padding: 0.625rem 32px;
  border-radius: 4px;
  border: none;
  margin: 0 0 0 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  font-family: "Poppins";
  width: 223px;
  transition: 0.3s;
  color: white;
}

.nav-button:hover {
  opacity: 0.85;
  gap: 20px;
}

.nav-item {
  position: relative;
}

.nav-popup1 {
  visibility: hidden;
  position: absolute;
  background-color: white;
  height: auto;
  top: 3.3rem;
  left: -25rem;
  padding: 1.5rem 2.5rem;
  width: 900px;
  z-index: +1;
  transition: 0.2s;
}

.popup1-head {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.popup1-head img {
  width: 28px;
  height: 28px;
  object-fit: contain;
  border-radius: 50%;
  border: 1px solid lightgray;
}

.popup1-desc {
  padding: 0 2.3rem 0 !important;
  --tw-text-opacity: 1;
  color: rgb(61 71 81 / var(--tw-text-opacity));
}

.popup1-text {
  padding: 0.3rem 0 0 0 !important;
  --tw-text-opacity: 1;
  color: rgb(61 71 81 / var(--tw-text-opacity));
}

.popup1-hr {
  height: 2px;
  background-color: lightgray;
}

.nav-item:hover .nav-popup1 {
  visibility: visible;
}

.popup1-body {
  margin: 2rem 0 0;
}

.popup1-sec1{
  margin-right: 2rem;
}
.popup1-sec2{
  margin-right: 2rem;
}

.popup1-sec2 p {
  margin: 0.3rem 0 0;
  --tw-text-opacity: 1;
  color: rgb(61 71 81 / var(--tw-text-opacity));
}

.popup1-box {
  display: flex;
  gap: 0.5rem;
  margin: 0 0 0.3rem 0;
}

.popup1-box-icon {
  margin: 0.2rem 0 0;
  font-size: 18px;
}

.popup1-box div h6 {
  margin: 0;
}

.popup1-box div p {
  font-size: 14px;
}


@media only screen and (max-width: 1200px) {
  .nav-link {
    padding: 0.55rem 0.5rem 0 !important;
  }

  .nav-popup1 {
    left: -28rem;
  }
}

@media only screen and (max-width: 991px) {
  .nav-link {
    padding: 16px 8px !important;
  }

  .nav-popup1 {
    visibility: visible;
    display: none;
  }

  .nav-item:hover .nav-popup1 {
    display: block;
  }

  .nav-item:hover .nav-popup1 {
    left: -2rem;
    width: auto;
  }

  .nav-button {
    margin: 0 0 0 8px;
  }

  .popup1-sec1{
    margin-right: 0.5rem;
  }
  .popup1-sec2{
    margin-right: 0.5rem;
  }
}

@media only screen and (max-width: 767px) {
  .nav-popup1 {
    position: static;
    padding: 0.5rem 8px;
    width: auto;
    box-shadow: none !important;
  }

  .nav-item:hover .nav-popup1 {
    display: none;
  }

  .nav-popup1.active {
    display: block !important;
  }

  .popup1-head {
    border: 2px solid lightgray;
    border-radius: 10px;
    padding: 1rem;
    width: 340px;
  }

  .popup1-head h5 {
    font-size: 18px;
  }

  .popup1-hr {
    display: none;
  }

  .popup1-desc {
    display: none;
  }

  .popup1-sec2 {
    margin-bottom: 1rem;
  }


  
}

@media only screen and (max-width: 475px) {
  .custom-navbar {
    padding: 1rem 0rem;
  }
  
  .navbar-brand img {
    width: 150px;
  }
}

.nav-link input {
  width: 80px;
}
