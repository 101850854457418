.btn-square {
    width: 38px;
    height: 38px;
}

.btn-sm-square {
    width: 32px;
    height: 32px;
}

.btn-lg-square {
    width: 48px;
    height: 48px;
}
.feature-item{
    height: 350px; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.feature-item i {
    font-size: 4rem; 
    max-width: 100%; 
    max-height: 100%; 
    color: #284F8D;
    margin-bottom: 1rem; 
  }
  
  .spark-powerd-items-aiboot .feature-item h5{
    color: #284F8D;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 1rem;
  }


  
.btn-square,
.btn-sm-square,
.btn-lg-square {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: normal;
    border-radius: 50px;
}

.spark-flow-img-fluid-rounded1{
    max-width: 700px;
    width: 100%;
    max-height: 100%; 
    height: 90%;
}
.spark-flow-img-fluid-rounded2{
    max-width: 600px;
    width: 100%;
    max-height: 100%; 
    height: 80%;
}
.spark-manage-scrool-animations{
    /* animation: float 3s infinite ease-in-out; */
}
.spark-transform-business-heading{
    color: var(--primary-color);
    fontSize: 40px;
    fontWeight: 700;
}
@keyframes float {
    0%,
    100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-20px);
    }
  }

  @media only screen and (max-width: 767px) {
    .spark-transform-business-heading{
        text-align: center;
    }
  
    .spark-powerd-items-aiboot{
        margin: .5rem 0rem
    }
    .ai-saas-based-applications{
        text-align: center
    }
    .sparkixtech-website-iframe-Link{
        width: 100%;
        height: 100%;
        min-height:200px;
        margin-bottom: .5rem;
    }
   
  }
  @media only screen and (max-width: 1200px) {

    .spark-flow-img-fluid-rounded2{
        max-width: 600px;
        width: 100%;
        max-height: 90%; 
        height: 50%;
        margin-top:2rem;
    }
    .sparkixtech-website-image-Link{
        width: 100%;
        height: 100%;
        min-height:200px;
        margin-bottom: .5rem;   
    }
    .feature-item{
        height:auto !important;
        
    }
    .ai-saas-based-applications ul{
        padding-left: 0rem !important;
    }
    .generative-ai-for-enterprises ul{
        padding-left: 0rem !important;
    }
    .generative-ai-for-enterprises h1{
        text-align: center;
    }
    .ai-saas-based-application ul{
        padding-left: 0rem !important;
    }
    .ai-saas-based-application h1{
        text-align: center;
    }
  }