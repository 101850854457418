/* .industries-main{
    min-height: 100vh;
} */

.industries-main h1{
    font-family: 'Poppins';
    margin: 1rem 0 4rem;
}

.indus-card{
    padding: 13px 0;
    margin: 1rem 1rem;
    border-style: solid;
    border-width: 2px;
    border-color: #DDDDDD;
    border-radius: 14px;
    box-shadow: 5px 4px 18px -6px rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.indus-card-icon{
    font-size: 64px;
}

.indus-card h5{
    font-family: 'Poppins';
    margin: 1rem 0 0;
    font-weight: 600;
}
