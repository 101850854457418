.about-section-top {
  padding: 0rem 2rem;
  margin-top: 25vh;
}

.about-section-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 0 2rem;
}

.about-section-block div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-section-icon {
  font-size: 20px;
}

.about-section-block h1 {
  font-size: 47px;
  font-weight: bold;
  font-family: "Poppins";
}

.about-section-block p {
  color: #454545;
  font-family: "Poppins";
}

.about-section-bottom {
  height: auto;
  background-image: url("../../../assets/background/map3.png");
  background-size: 65%;
  background-position: 100% 15%;
  background-repeat: no-repeat;
  padding: 2rem 2rem;
  margin-top: 15vh;
}

.about-sec-details {
  padding: 0 3rem 0 0;
}

.about-section-bottom h2 {
  /* font-weight: bold; */
  font-family: "Poppins";
  margin-bottom: 2.5rem;
}

.about-section-bottom p {
  font-size: 17px;
  margin-bottom: 2.5rem;
  font-family: "Poppins";
}

.about-sec-award {
  width: 25%;
  opacity: 1;
  padding: 0 1rem;
  margin-bottom: 3rem;
}

.about-sec-award img {
  max-width: 100%;
  max-height: 80px;
  height: auto;
  filter: grayscale(1);
  vertical-align: middle;
  border-style: none;
  overflow-clip-margin: content-box;
  overflow: clip;
}

.wu-info {
  margin-top: 5rem;
  margin-bottom: 28px;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 1rem !important;
}

.wu-info h3 {
  padding: 2px 10px;
  font-size: 20px;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 140%;
}

.wu-info p {
  line-height: 170%;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 400;
  font-family: "Poppins";
}

.First-box {
  border-right: 2px solid lightgray;
}

.First-box h3 {
  border-left: 3px solid #0ebab1;
}

.Sec-box {
  border-right: 2px solid lightgray;
}

.Sec-box h3 {
  border-left: 3px solid #fcd53f;
}

.Third-box h3 {
  border-left: 3px solid #9747ff;
}

@media only screen and (max-width: 1150px) {
  .about-sec-details {
    padding: 0;
  }
}

@media only screen and (max-width: 991px) {
  .about-section-bottom h2 {
    text-align: center;
  }

  .about-section-top {
    padding: 1rem 2rem;
  }
}

@media only screen and (max-width: 767px) {
  .about-section-bottom {
    background-image: none;
    padding: 3rem 1rem 2rem;
  }

  .First-box {
    border-right: none;
    border-bottom: 1px solid lightgray;
  }

  .Sec-box {
    border-right: none;
    border-bottom: 1px solid lightgray;
  }

  .wu-info {
    padding: 0 !important;
    margin: 1.5rem 1rem;
  }
}

@media only screen and (max-width: 575px) {
  .about-section-bottom h2 {
    font-size: 22.5px;
    margin-bottom: 2rem;
  }

  .about-sec-award {
    width: 33%;
    opacity: 1;
    padding: 0 1rem;
    margin-bottom: 3rem;
  }

  .about-section-block h1 {
    font-size: 30px;
  }

  .about-section-block p {
    font-size: 15px;
    text-align: center;
  }

  .about-section-top {
    padding: 2rem 0.5rem 1rem;
    margin-top: 15vh;
  }

  .about-section-bottom {
    padding: 3rem 1rem 3rem;
    margin: 5vh 0;
  }
}

@media only screen and (max-width: 475px) {
  .about-section-bottom {
    padding: 3rem 0.5rem 3rem;
  }
}
