.hero-section {
    padding: 5rem 2rem;
    background-image: url('../../../assets/background/hero-bg.png');
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    height: auto;
    color: white;
    /* margin-bottom: 25vh; */
}

.hero-description {
    padding: 0 7rem 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}

.hero-title {
    font-size: 45px;
    font-weight: 600;
    font-family: 'Poppins';
}

.hero-text {
    padding: 2rem 0 1rem;
    font-size: 17px;
    font-family: 'Poppins';
    font-style: normal;
}

.hero-button {
    padding: 0.625rem 1rem;
    font-weight: 500;
    border-radius: 0.5rem;
    border: none;
    /* color: white;
    background-color: black; */
    width: 143px;
}

.hero-button:hover {
    opacity: 0.85;
}

.hero-img {
    height: 450px;
    width: 100%;
    object-fit: contain;
}

.hero-slider{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

@media only screen and (max-width: 1150px) {
    .hero-description {
        padding: 0;
    }
}

@media only screen and (max-width: 991px) {
    .hero-description {
        padding: 0 2rem;
    }
}

@media only screen and (max-width: 767px) {

    .hero-title {
        font-size: 40px;
    }

    .hero-text {
        font-size: 16px;
    }
}

@media only screen and (max-width: 767px) {
    .hero-description {
        padding: 0 1rem;
    }

    .hero-section {
        padding: 3rem 1rem 0;
    }

    .hero-title {
        font-size: 30px;
        font-weight: 600;
    }

    .hero-text {
        font-weight: 400;
        padding: 2rem 0 1rem;
        font-size: 14px;
    }
}

@media only screen and (max-width: 475px) {
    .hero-section {
        padding: 3rem 0.5rem 0;
    }
    .hero-title {
        font-size: 27px;
        font-weight: 600;
    }
}